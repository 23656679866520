import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, Chip, DialogActions, DialogContent, MenuItem, TextField} from "@mui/material";
import {Autocomplete, LoadingButton} from "@mui/lab";
import {findCategoryLimit} from "../../core/utils/utils";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as React from "react";
import {useEffect, useState} from "react";
import MultipleSelect from "../Admin/MultipleSelect";
import {useCreateTransferRequestMutation} from "../../core/api/transfer-request-api";

export default function TransferRequestDialog(props) {
    const {open, onClose, budgets} = props;
    const [createTransferRequest, response] = useCreateTransferRequestMutation();
    const currentMonthNumber = new Date().getMonth();
    //const currentMonthNumber = 8;

    const [state, setState] = useState({
        belcoinsAmount: 0,
        comment: "",
        selectedCategory: {},
        monthBudgets: [],
        monthBudgetsOut: [],
        isUpdateConfirmDialogOpen: false,
        isRequestLoading: false,
        isUpdateBalanceClick: false,
        isResponseReady: false,
        validateError: {},
    });

    const [selectedMonthIn, setSelectedMonthIn] = useState({month: "", sum: 0})
    const [selectedMonthsOut, setSelectedMonthsOut] = useState([])
    const [isNeedToClear, setIsNeedToClear] = useState(false)

    useEffect(() => {
        console.log("cat")
        findMonthsBudgetByCategory(state.selectedCategory);
        console.log(state)

    }, [state.selectedCategory])

    useEffect(() => {
        const isResponseLoadingDialog = state.isUpdateBalanceClick && (response.status !== 'rejected' ||
            response.status !== 'fulfilled');
        const isReadyToDialog = (response.status === 'rejected' || response.status === 'fulfilled');
        setState({
            ...state,
            isRequestLoading: isResponseLoadingDialog,
            isUpdateBalanceClick: false
        })
        if (isReadyToDialog) {
            handleClose(true, response);
        }

    }, [response])

    const findMonthsBudgetByCategory = (category) => {
        if (category === undefined || budgets === undefined || budgets.length === 0)  return;
        let monthsBudgetList =  [];
        const globalBudget = budgets.filter(cat => cat.id === category.id);
        if (globalBudget !== undefined && globalBudget.length > 0) {
            const budget = globalBudget[0];
            for (let i = currentMonthNumber;  i<12; i++ ) {
                monthsBudgetList.push(budget.monthsBudget[i]);
            }
            //handleSetSelectedMonthsOutSize([])
            setIsNeedToClear(true);
            setSelectedMonthIn({
                month: "",
                sum: 0
            })
            setState({
                ...state,
                selectedCategory: category,
                monthBudgets: monthsBudgetList,
                monthBudgetsOut: monthsBudgetList
            })
        }
    }
    function handleSelectCategory(newValue) {
        let monthsBudgetList =  [];
        //findMonthsBudgetByCategory(newValue)
        const globalBudget = budgets.filter(cat => cat.id === newValue.id);
        if (globalBudget !== undefined) {
            const budget = globalBudget[0];
            for (let i = currentMonthNumber;  i<12; i++ ) {
                monthsBudgetList.push(budget.monthsBudget[i]);
            }
            setSelectedMonthIn({
                month: "",
                sum: 0
            })
            setIsNeedToClear(true)
            setState({
                ...state,
                selectedCategory: newValue,
                monthBudgets: monthsBudgetList,
                monthBudgetsOut: monthsBudgetList
            })
        }
    }

    function handleSelectMonthIn(newValue) {
        let out = state.monthBudgets.filter(month => (month.month !== newValue.month) &&  (month.sum > 0));
        let outMonths = [];
        out.forEach(item => {
            outMonths.push(item.month + "(" + item.sum + ")")
        })
        setSelectedMonthIn(newValue);
        setIsNeedToClear(true);
        setState({
            ...state,
            monthBudgetsOut: outMonths
        })
    }

    function validateRequest() {
        let errors = {};
        if (Object.keys(state.selectedCategory).length === 0) {
            errors = {...errors, categoryId: true}
        }
        if (Object.keys(selectedMonthIn).length === 0) {
            errors = {...errors, selectedMonthIn: true}
        }
        if (state.comment.trim() === "" ) {
            errors = {...errors, comment: true}
        }

        setState({
            ...state,
            validateError: errors,
            isRequestLoading: true,
            isUpdateBalanceClick: true
        })

        return Object.keys(errors).length === 0;
    }

    function handleCreateTransferRequest() {
        if (validateRequest()) {
            createTransferRequest({
                budgetId: state.selectedCategory.id,
                monthTransferIn: selectedMonthIn.month,
                monthsTransferOut: selectedMonthsOut,
                comment: state.comment
            })
        }
    }

    function findMonthBudget(selectedMonth) {
        const b=  state.monthBudgets.filter(month => month.month === selectedMonth);
        if (b.length > 0) {
            return b[0].sum;
        }
        return 0;
    }
    function handleSetSelectedMonthsOutSize(selected) {
        console.log("selected")
        console.log(selected)
        if (selected.length <= 0) {
            setSelectedMonthsOut([{month: "", sum: 0}]);
            setState({
                ...state,
                belcoinsAmount: 0,
            })
            return;
        }
        let selectedMonths = [];
        let belcoinsAmount = 0;
        selected.forEach(item => {
                const sumIndex = item.indexOf("(");
                const month = item.substring(0, sumIndex).trim().replace("(", "")
                selectedMonths.push(month);
                const budgetSum = findMonthBudget(month);
                belcoinsAmount += Number(budgetSum)

            });

        setSelectedMonthsOut(selectedMonths);
        setState({
            ...state,
            belcoinsAmount: belcoinsAmount,
        })
    }

    const handleClose = (responseReady, responseData) => {
        setState({
            ...state,
            selectedCategory: {},
            isRequestLoading: false,
            isUpdateBalanceClick: false,
            validateError: {},
        })
        setSelectedMonthIn({month:"", sum: 0})
        setSelectedMonthsOut([])

        onClose({
            isResponseReady: responseReady === true,
            data: responseData
        });
    }

    const getMonthsString = (budget) => {
        if ((budget.month === "") && (budget.sum === 0)) {
            return ""
        } else {
            return budget.month + "(" + budget.sum + ")";
        }
    }

    function handleChangeComment(event) {
        setState({
            ...state,
            comment: event.target.value
        })
    }

    return (
        <Dialog onClose={onClose} open={open}
                PaperProps={{ sx: { width: "70%" } }}>
            <DialogTitle>
                Создание заявки на пополнение бюджета
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={budgets}
                    sx={{mt: 4}}
                    onChange={(event, newValue) => {
                        handleSelectCategory(newValue);
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    getOptionLabel={(item) => item.name || ""}

                    //value={props.selectedCategory === undefined ? "" : props.selectedCategory.name}

                    renderInput={(params) =>
                        <TextField {...params}
                                   variant="outlined"
                                   color="warning"
                                   name="categoryId"
                                   error={state.validateError.categoryId}
                                   helperText={state.validateError.categoryId
                                       ? "Поле не должно быть пустым."
                                       : ""}
                                   label="Выберите категорию для пополенения"/>}
                />

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={state.monthBudgets}
                    value={selectedMonthIn}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{mt: 4}}
                    onChange={(event, newValue) => {
                        handleSelectMonthIn(newValue);
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.month}>
                            {getMonthsString(option)}
                        </li>
                    )}
                    getOptionLabel={(item) => getMonthsString(item) || ""}

                    //value={ props.selectedMonth != null ? props.selectedMonth.name  + "(" + props.selectedMonth.sum + ")" || ""}

                    renderInput={(params) =>
                        <TextField {...params}
                                   variant="outlined"
                                   color="warning"
                                   name="month"
                                   error={state.validateError.selectedMonthIn}
                                   helperText={state.validateError.selectedMonthIn
                                       ? "Поле не должно быть пустым."
                                       : ""}
                                   label="Выберите месяц в который нужно начислить belcoins"/>}
                />

                <MultipleSelect label={"Выберите месяцы из которых спишем belcoins"}
                                labelId={"outMonths"}
                                items={state.monthBudgetsOut}
                                defaultSelectedItems={selectedMonthsOut}
                                clearSelected={isNeedToClear}
                                setIsNeedToClear={setIsNeedToClear}
                                setSelectedItems={handleSetSelectedMonthsOutSize}/>

                <TextField
                    required
                    sx={{mt: 2}}
                    value={state.comment}
                    id="new-product-description"
                    label="Комментарий для админа"
                    variant="outlined"
                    color="warning"
                    onChange={handleChangeComment}
                    fullWidth
                    error={state.validateError.comment}
                    helperText={state.validateError.comment
                        ? "Поле не может быть пустым"
                        : ""}
                />
                <Chip
                    sx={{mt: 3}}
                    size={"small"}
                    color="warning"
                    label={"В бюджет \"" + state.selectedCategory.name +"(" + ((selectedMonthIn !== undefined && selectedMonthIn !== null)? selectedMonthIn.month : "" )+  ")\" будет перенесена сумма: " + state.belcoinsAmount}
                />

            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}>
                    Отмена
                </Button>
                <LoadingButton
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={handleCreateTransferRequest}
                    loading={state.isRequestLoading}>
                    Отправить
                </LoadingButton>
            </DialogActions>

        </Dialog>);
}
