import * as React from 'react';
import {useState} from 'react';
import {Box, Chip, Tooltip} from "@mui/material";

import PropTypes from "prop-types";
import {ThumbUpOffAlt} from "@mui/icons-material";
import BlockIcon from '@mui/icons-material/Block';
import {useGetAllAccrualRequestsQuery, useGetCategoriesQuery} from "../../core/api/accrual-request-api";
import ApproveTransferConfirmDialog from "./ApproveTransferConfirmDialog";
import {useLocation} from "react-router";
import {isAdminMode} from "../../core/utils/utils";
import {useGetTransferRequestsQuery} from "../../core/api/transfer-request-api";


export default function TransferRequestButton(props) {
    console.log("TransferRequestButton")
    console.log(props)

    const location = useLocation()
    const isAdmin = isAdminMode(location.pathname);

    const {data: transfers = [], isSuccess, refetch} = useGetTransferRequestsQuery();
    const {data: response = [], isSuccess: isSuccessCategory, isFetching, refetch: refetchBudgets} = useGetCategoriesQuery();


    const [state, setState] = useState({
        isConfirmDialogOpen: false,
        isApproveButtonClicked: false,
        color: "default",
        variant: "outlined"
    })


    const handleConfirm = () => {
        setState({
            ...state,
            isConfirmDialogOpen: true,
            isApproveButtonClicked: true
        })
    }

    const handleReject = () => {
        setState({
            ...state,
            isConfirmDialogOpen: true,
            isApproveButtonClicked: false
        })
    }

    const handleClose = () => {
        refetch();
        setState({
            ...state,
            isConfirmDialogOpen: false
        })
    }

    return (!isAdmin?
        <Box>
            Ожидание
        </Box> :
        <Box>
        <Box>
            <Tooltip title={"Подтвердить начисление белкоинов"}>
                <Chip label="Одобрить"
                      size="small"
                      color={"warning"}
                      icon={<ThumbUpOffAlt/>}
                      variant={"contained"}
                      onClick={() => handleConfirm()}/>
            </Tooltip>
        </Box>
        <Box>
            <Tooltip sx={{mt:1}}
                     title={"Отклонить начисление белкоинов"}>
                <Chip label="Отклонить"
                      size="small"
                      color={"warning"}
                      icon={<BlockIcon/>}
                      variant={"outlined"}
                      onClick={() => handleReject()}/>
            </Tooltip>
        </Box>
        <ApproveTransferConfirmDialog onClose={handleClose}
                              open={state.isConfirmDialogOpen}
                              requestId={props.request.id}
                              isApprove={state.isApproveButtonClicked}
                              refetch={refetch}
                              refetchBudgets={refetchBudgets}

        />

    </Box>);
}

TransferRequestButton.propTypes = {
    request: PropTypes.object.isRequired,
};