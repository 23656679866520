import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, Checkbox, Chip, DialogActions, DialogContent, FormControlLabel, MenuItem, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useUpdateBalanceMutation} from "../../../core/api/balance-api";
import ResponseDialog from "../../ResponseDialog";
import {LoadingButton} from "@mui/lab";
import {useGetCategoriesQuery} from "../../../core/api/accrual-request-api";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {findCategoryLimit} from "../../../core/utils/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function BalancesChangeDialog(props) {
    const {onClose, open, buttonId, employeeIdList} = props;
    const [updateBalance, balanceUpdateResponse] = useUpdateBalanceMutation();
    const {data: categories = []} = useGetCategoriesQuery();

    const [state, setState] = useState({
        belcoinsAmount: 0,
        comment: "",
        isUpdateConfirmDialogOpen: false,
        isRequestLoading: false,
        isUpdateBalanceClick: false,
        isResponseReady: false,
        validateError: {},
        category: "",
        isBelkaInBag: false,
        isOtherCategory: false
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isUpdateBalanceClick && (balanceUpdateResponse.status !== 'rejected' || balanceUpdateResponse.status !== 'fulfilled');
        const isResponseReady = (balanceUpdateResponse.status === 'rejected' || balanceUpdateResponse.status === 'fulfilled')
        setState({...state,
            isRequestLoading: isResponseLoadingDialog,
            isUpdateBalanceClick: false})
        if (isResponseReady) {
            handleClose(true);
        }

    }, [balanceUpdateResponse])

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: false,
            isResponseReady: false,
            isUpdateBalanceClick: false,
            validateError: {},
            isUpdateConfirmDialogOpen: true
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: balanceUpdateResponse
        });
    };

    const handleBelcoinsAmountChange = (event) => {
        if (validateRequiredFields()) {
            updateBalance({
                employeeIdList: employeeIdList,
                category: state.category,
                sum: buttonId === "increase-button" ? state.belcoinsAmount : -state.belcoinsAmount,
                comment: state.comment,
                isBelkaInBag: state.isBelkaInBag
            })
            setState({...state,
                isUpdateBalanceClick: true
            })
        }
    };
    const handleAmountChange = (event) => {
        setState({...state, belcoinsAmount: event.target.value})
    }
    const handleCommentChange = (event) => {
        setState({...state, comment: event.target.value})
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.belcoinsAmount <= 0) {
            errors = {...errors, belcoinsAmount: true};
        }
        if (buttonId === "increase-button" && state.category <= 0) {
            errors = {...errors, category: true};
        }
        if (state.isOtherCategory && state.comment.trim() === "") {
            errors = {...errors, comment: true};
        }
        setState({...state, validateError: errors})
        return Object.keys(errors).length === 0;
    }

    const handleCategoryChange = (event) => {
        setState({
            ...state,
            category:  event.target.value,
            isOtherCategory: event.target.value === "Другое" || event.target.value === "Участие в Cross проектах"
        })
    }
    const handleCheckboxChange = (event) => {
        setState({
            ...state,
            isBelkaInBag: event.target.checked
        })
    }

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                {buttonId === "increase-button" ?
                    "Сколько белкоинов начислим?" :
                    "Сколько белкоинов спишем?"}
            </DialogTitle>
            <DialogContent>
                {buttonId === "increase-button" &&
                    <Box>
                    <TextField
                        required
                        style={{
                            textAlign: "left"
                        }}
                        select
                        fullWidth
                        sx={{mt: 2}}
                        label="Категория начисления"
                        name="category"
                        variant="outlined"
                        color="warning"
                        value={state.category}
                        helperText={state.validateError.category ? "Поле не должно быть пустым" : ""}
                        error={state.validateError.category}
                        SelectProps={{"MenuProps": MenuProps}}
                        onChange={handleCategoryChange}
                    >
                        {categories.map((option, index) => (
                            <MenuItem
                                key={`category_select_image_${index}`}
                                value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                {(state.category !== "") &&
                    <Chip label={"Лимит на месяц по категории: " + findCategoryLimit(state.category, categories)}
                    size="small"
                    color={"warning"}
                    icon={<ErrorOutlineIcon/>}
                    variant={"contained"}
                    />
                }
                    </Box>
                }
                <TextField
                    sx={{mt: 3}}
                    value={state.belcoinsAmount}
                    autoFocus
                    id="belcoins-amount"
                    label="Количество белкоинов"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleAmountChange}
                    type={"number"}
                    fullWidth
                    name="belcoinsAmount"
                    error={state.validateError.belcoinsAmount}
                    helperText={state.validateError.belcoinsAmount
                        ? "Указанно некорректное количество"
                        : ""}
                />
                {buttonId !== "increase-button" &&
                    <FormControlLabel
                        control={<Checkbox checked={state.agreementForDataProcessing}
                                           onChange={handleCheckboxChange}
                                           inputProps={{'aria-label': 'controlled'}}/>}
                        label="Списание за Белку в мешке"
                    />
                }
                <TextField
                    sx={{mt: 3}}
                    value={state.comment}
                    id="belcoins-amount-comment"
                    label="Комментарий"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleCommentChange}
                    fullWidth
                    name="comment"
                    error={state.validateError.comment}
                    helperText={state.validateError.comment
                        ? "Для выбранной категории нужно оставить комментарий"
                        : ""}
                />
                <Chip
                    sx={{mt: 3}}
                    size={"small"}
                    color="warning"
                    label={employeeIdList ? "Выбрано сотрудников: " + employeeIdList.length : 0}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена</Button>
                <LoadingButton
                    size="small"
                    onClick={handleBelcoinsAmountChange}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    {buttonId === "increase-button" ?
                        "Начислить" :
                        "Списать"}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
}

BalancesChangeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    buttonId: PropTypes.string.isRequired,
    employeeIdList: PropTypes.array.isRequired,
    refetchBalance: PropTypes.func.isRequired
};
