import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Chip, DialogActions, DialogContent, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useUpdateBalanceMutation} from "../../../core/api/balance-api";
import {Autocomplete, LoadingButton} from "@mui/lab";
import {useUpdateBudgetMutation} from "../../../core/api/budget-api";


export default function BudgetChangeDialog(props) {
    const {onClose, open, categories, budgets} = props;
    const [updateBudget, updateBudgetResponse] = useUpdateBudgetMutation();
    const [state, setState] = useState({
        belcoinsAmount: 0,
        comment: "",
        selectedCategory: {},
        monthBudgets: [],
        selectedMonth: [],
        isUpdateConfirmDialogOpen: false,
        isRequestLoading: false,
        isUpdateBalanceClick: false,
        isResponseReady: false,
        validateError: {}
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isUpdateBalanceClick && (updateBudgetResponse.status !== 'rejected' || updateBudgetResponse.status !== 'fulfilled');
        const isResponseReady = (updateBudgetResponse.status === 'rejected' || updateBudgetResponse.status === 'fulfilled')
        setState({...state,
            isRequestLoading: isResponseLoadingDialog,
            isUpdateBalanceClick: false})
        if (isResponseReady) {
            handleClose(true);
        }

    }, [updateBudgetResponse])

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: false,
            isResponseReady: false,
            isUpdateBalanceClick: false,
            validateError: {},
            isUpdateConfirmDialogOpen: true
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: updateBudgetResponse
        });
    };

    const monthNameToNumber = (monthName) => {
        const months = {
            JANUARY: 1,
            FEBRUARY: 2,
            MARCH: 3,
            APRIL: 4,
            MAY: 5,
            JUNE: 6,
            JULY: 7,
            AUGUST: 8,
            SEPTEMBER: 9,
            OCTOBER: 10,
            NOVEMBER: 11,
            DECEMBER: 12
        };

        // Convert to uppercase in case it's not, to ensure a match
        const upperCaseMonthName = monthName.toUpperCase();

        return months[upperCaseMonthName] || null;
    }
    const handleBelcoinsAmountChange = (event) => {
        if (validateRequiredFields()) {
            updateBudget({
                budgetId: state.selectedCategory.id,
                month: monthNameToNumber(state.selectedMonth.month),
                amount: state.belcoinsAmount,
                comment: state.comment
            })
            setState({...state,
                isUpdateBalanceClick: true
            })
        }
    };
    const handleAmountChange = (event) => {
        setState({...state, belcoinsAmount: event.target.value})
    }
    const handleCommentChange = (event) => {
        setState({...state, comment: event.target.value})
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.belcoinsAmount === undefined || state.belcoinsAmount === null || state.belcoinsAmount === 0) {
            errors = {...errors, belcoinsAmount: true};
        }
        if (state.selectedCategory === undefined || state.selectedCategory === null) {
            errors = {...errors,
                categoryId: true}
        }

        setState({...state, validateError: errors})
        return Object.keys(errors).length === 0;
    }

    function handleSelectCategory(newValue) {
        let monthsBudgetList =  [];
        const currentMonthNumber = new Date().getMonth();
        const globalBudget = budgets.filter(cat => cat.id === newValue.id);
        if (globalBudget !== undefined) {
            const budget = globalBudget[0];
            for (let i = currentMonthNumber;  i<12; i++ ) {
                monthsBudgetList.push(budget.monthsBudget[i]);
            }
            setState({
                ...state,
                selectedCategory: newValue,
                monthBudgets: monthsBudgetList
            })
        }
    }

    function handleSelectMonths(newValue) {
        setState({
            ...state,
            selectedMonth: newValue
        })
    }
    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                {"Внесение изменений в бюджет"}
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={categories}
                    sx={{mt: 4}}
                    onChange={(event, newValue) => {
                        handleSelectCategory(newValue);
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                    getOptionLabel={(item) => item.name || ""}

                    //value={props.selectedCategory === undefined ? "" : props.selectedCategory.name}

                    renderInput={(params) =>
                        <TextField {...params}
                                   variant="outlined"
                                   color="warning"
                                   name="categoryId"
                                   error={state.validateError.categoryId}
                                   helperText={state.validateError.categoryId
                                       ? "Поле не должно быть пустым."
                                       : ""}
                                   label="Выберите категорию"/>}
                />
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={state.monthBudgets}
                    sx={{mt: 4}}
                    onChange={(event, newValue) => {
                        handleSelectMonths(newValue);
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.month}>
                            {option.month + "(" + option.sum + ")"}
                        </li>
                    )}
                    getOptionLabel={(item) => item.month + "(" + item.sum + ")" || ""}

                    //value={props.selectedCategory === undefined ? "" : props.selectedCategory.name}

                    renderInput={(params) =>
                        <TextField {...params}
                                   variant="outlined"
                                   color="warning"
                                   name="monthd"
                                   error={state.validateError.month}
                                   helperText={state.validateError.month
                                       ? "Поле не должно быть пустым."
                                       : ""}
                                   label="Выберите месяц"/>}
                />
                <TextField
                    sx={{mt: 3}}
                    value={state.belcoinsAmount}
                    autoFocus
                    id="belcoins-amount"
                    label="Количество белкоинов"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleAmountChange}
                    type={"number"}
                    fullWidth
                    name="belcoinsAmount"
                    error={state.validateError.belcoinsAmount}
                    helperText={state.validateError.belcoinsAmount
                        ? "Указанно некорректное количество"
                        : ""}
                />
                <TextField
                    sx={{mt: 3}}
                    value={state.comment}
                    id="belcoins-amount-comment"
                    label="Комментарий"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleCommentChange}
                    fullWidth
                />
                <Chip
                    sx={{mt: 3}}
                    size={"small"}
                    color="warning"
                    label={"Будет обновлена категория " + state.selectedCategory.name +". Месяц: " + state.selectedMonth.month +  ". Сумма: " + state.belcoinsAmount}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена</Button>
                <LoadingButton
                    size="small"
                    onClick={handleBelcoinsAmountChange}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    {
                        "Обновить"}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
}

BudgetChangeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    budgets: PropTypes.array.isRequired,
    refetchBalance: PropTypes.func.isRequired
};
