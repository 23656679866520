import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router";
import {getDateInServerTimeZone, isAdminMode} from "../../../core/utils/utils";
import ResponseDialog from "../../ResponseDialog";
import {useGetCategoriesQuery} from "../../../core/api/accrual-request-api";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import BudgetChangeDialog from "./BudgetChangeDialog";
import ExportGridToExcel from "../ExportGridToExcel";
import {useCreateBudgetMutation} from "../../../core/api/budget-api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TransferRequestDialog from "../../Request/TransferRequestDialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useGetTransferRequestsQuery} from "../../../core/api/transfer-request-api";
import UserIdsListComponent from "../../Request/UserIdsListComponent";
import ActionAccrualRequestButton from "../../Request/ActionAccrualRequestButton";
import TransferRequestButton from "../../Request/TransferRequestButton";
import {format} from "date-fns";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'name', headerName: 'Категория', width: 300},
    { field: 'JANUARY', headerName: 'Jan', width: 40 },
    { field: 'FEBRUARY', headerName: 'Feb', width: 40 },
    { field: 'MARCH', headerName: 'Mar', width: 40 },
    { field: 'APRIL', headerName: 'Apr', width: 40 },
    { field: 'MAY', headerName: 'May', width: 40 },
    { field: 'JUNE', headerName: 'Jun', width: 40 },
    { field: 'JULY', headerName: 'Jul', width: 40 },
    { field: 'AUGUST', headerName: 'Aug', width: 40 },
    { field: 'SEPTEMBER', headerName: 'Sep', width: 40 },
    { field: 'OCTOBER', headerName: 'Oct', width: 40 },
    { field: 'NOVEMBER', headerName: 'Nov', width: 40 },
    { field: 'DECEMBER', headerName: 'Dec', width: 40 },
    {field: 'limit', headerName: 'Год', width: 100},
];

const columnsTransfers = [
    {field: 'id', headerName: 'id', hide: true},
    {
        field: 'createdAt', headerName: 'Дата', width: 130,
        renderCell: (params) =>
            <div>{format(getDateInServerTimeZone(params.row.createdAt), "dd.MM.yyyy HH:mm")}</div>
    },
    {field: 'authorName', headerName: 'ФИО автора', width: 150},
    {field: 'categoryName', headerName: 'Категория', width: 150},
    {field: 'monthIn', headerName: 'Месяц', width: 110},
    {field: 'monthOut', headerName: 'Месяцы списания', width: 110,
        renderCell: (params) => {
            return <UserIdsListComponent data={params.row.monthOut}/>;
        }
    },
    {field: 'sumForTransfer', headerName: 'Сумма переноса', width: 60},
    {field: 'status', headerName: 'Статус', width: 150,
        renderCell: (params) => {
            if (params.row.status === 'APPROVED') {
                return <div>Одобрено</div>;
            } else if (params.row.status === 'REJECTED') {
                return <div>Отклонено</div>;
            } else {
                return <TransferRequestButton request={params.row}/>;
            }
        }
    },
    {field: 'comment', headerName: 'Комментарий', width: 200},
    {field: 'adminComment', headerName: 'Админ', width: 200},


];


export default function BudgetControl(props) {
    const {data: response = [], isSuccess: isSuccessCategory, isFetching, refetch} = useGetCategoriesQuery();

    const [categories, setCategories] = useState([]);
    const {createBudget, createResponse} = useCreateBudgetMutation();
    const location = useLocation()
    const isAdmin = isAdminMode(location.pathname);
    const {data: transferRequests = [], isSuccess: isSuccessTransfers, refetch: refetchTransfers} = useGetTransferRequestsQuery();

    const [state, setState] = useState({
        budget: [],
        isResponseDialogOpen: false,
        responseData: {},
        isSaveButtonClick: false,
        isRequestLoading: false,
        selectedCategory: [],
        isBalancesChangeResponseDialogOpen: false,
        balanceChangeResponse: {},
        isBalancesChangeDialogOpen: false,
        controlButtonId: "",
        isTransferDialogOpen: false,
        responseTransferData: {},
        isResponseTransferDialogOpen: false
    });

    const [value, setValue] = React.useState(0);
    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    useEffect(() =>{
            if (response) {
                const rows = response.map(item => {
                    const monthBudgetMap = {};
                    item.monthsBudget.forEach(monthEntry => {
                        monthBudgetMap[monthEntry.month] = monthEntry.sum;
                    });

                    return {
                        id: item.id,
                        name: item.name,
                        limit: item.limit,
                        isDeleted: item.isDeleted,
                        ...monthBudgetMap // Expanding month budget to individual properties
                    };
                });
                setCategories(rows);
            }
        },
        [response])

    const handleResponseDialogClose = () => {
        setState({
            ...state,
            isResponseDialogOpen: false
        })
    }
    const handleResponseTransferDialogClose = () => {
        setState({
            ...state,
            isResponseTransferDialogOpen: false
        })
    }

    const handleUpdateBudget = (event) => {
        setState({
            ...state,
            controlButtonId: event.target.id,
            isBalancesChangeDialogOpen: true,
            isSaveButtonClick: true,
            isRequestLoading: true,
        })
    }

    const handleCreateNewCategory = (event) => {
        createBudget();
    }

    const handleClose = (balanceChangeResponse) => {
        setState({
            ...state,
            isResponseDialogOpen: balanceChangeResponse.isResponseReady,
            balanceChangeResponse: balanceChangeResponse.data,
            isBalancesChangeDialogOpen: false
        })
        refetch();
    }

    const getCategoryById = (selectedCategoriesId) => {
        let selectedCategories = [];
        categories.forEach(category => {
            selectedCategoriesId.forEach(selectedId => {
                if (category.id === selectedId) {
                    selectedCategories.push(category);
                }
            })
        })
        return selectedCategories;
    }

   const handleBackButton = () => {
       navigate('/request');
   }

    const handleCreateTransferRequest = () => {
        setState({
            ...state,
            isTransferDialogOpen: true
        })
    }

    const handleTransferClose = (responseData) => {
        setState({
            ...state,
            isTransferDialogOpen: false,
            isResponseTransferDialogOpen: true,
            responseTransferData: responseData.data
        })
    }


    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{pt:2, height: "500px", width: '100%'}}>{children}</Box>}
            </div>
        );
    }


    return (
        <Box className='page-main-box'>
            {isFetching ?
                <CircularProgress/> :
                    <Box sx={{display: 'block', width: '100%'}}>
                        {isAdmin &&
                            <Box>
                                <Stack spacing={2} direction="row" sx={{pt: 2, alignItems: 'center'}}>
                                    <Button id="increase-button"
                                            variant="contained"
                                            color="warning"
                                            onClick={handleUpdateBudget}
                                    >
                                        Скорректировать бюджет
                                    </Button>


                                    <ExportGridToExcel filename={"budget"}
                                                       data={state.selectedCategory.length === 0 ?
                                                           categories :
                                                           getCategoryById(state.selectedCategory)}/>

                                    <BudgetChangeDialog onClose={handleClose}
                                                        open={state.isBalancesChangeDialogOpen}
                                                        categories={categories}
                                                        budgets={response}
                                                        refetchBalance={refetch}
                                    />
                                    {state.balanceChangeResponse &&
                                        <ResponseDialog
                                            onClose={handleResponseDialogClose}
                                            open={state.isResponseDialogOpen}
                                            responseData={state.balanceChangeResponse ? state.balanceChangeResponse : {}}
                                            label={"Редактирование бюджета"}/>
                                    }
                                </Stack>
                            </Box>
                        }
                        <Box sx={{width: '100%', height: 600, mt: 2}}>
                            {!isAdmin &&
                                <Box>
                                    <Button sx={{mb: 2}}
                                        variant="outlined"
                                        color="error"
                                        onClick={handleBackButton}
                                        startIcon={<ArrowBackIcon />}>
                                            Назад
                                    </Button>
                                    <Button sx={{mb: 2, ml: 2}}
                                        variant="contained"
                                        color="warning"
                                        onClick={handleCreateTransferRequest}>
                                        Запросить пополнение бюджета
                                    </Button>
                                    <TransferRequestDialog
                                        open={state.isTransferDialogOpen}
                                        onClose={handleTransferClose}
                                        budgets={response}/>
                                    {state.responseTransferData &&
                                        <ResponseDialog
                                            onClose={handleResponseTransferDialogClose}
                                            open={state.isResponseTransferDialogOpen}
                                            responseData={state.responseTransferData ? state.responseTransferData : {}}
                                            label={"Перенос бюджета белкоинов"}
                                        />
                                    }
                                </Box>
                            }

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value}
                                      onChange={handleChangeValue}
                                      >
                                    <Tab label="Бюджет"
                                         sx={{ '&.Mui-selected': { color: ' #e74415' } }} />
                                    <Tab label="Запросы"
                                         sx={{ '&.Mui-selected': { color: ' #e74415' } }} />
                                    />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <DataGrid
                                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                    rows={categories}
                                    columns={columns}
                                    pageSize={20}
                                    rowsPerPageOptions={[20]}
                                    /* checkboxSelection={isAdmin}
                                     onSelectionModelChange={(newSelectionModel) => {
                                         setState({...state,
                                             selectedCategory: newSelectionModel})
                                     }}
                                     selectionModel={state.selectedCategory}*/
                                />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <DataGrid
                                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                    rows={transferRequests}
                                    columns={columnsTransfers}
                                    pageSize={20}
                                    rowsPerPageOptions={[20]}
                                    /* checkboxSelection={isAdmin}
                                     onSelectionModelChange={(newSelectionModel) => {
                                         setState({...state,
                                             selectedCategory: newSelectionModel})
                                     }}
                                     selectionModel={state.selectedCategory}*/
                                />
                            </CustomTabPanel>


                        </Box>
                </Box>
            }

        </Box>
    );
}



