import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

export const accrualRequestApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTransferRequests: build.query({
            query: ( ) => `/budget-transfer/list`,
            providesTags: ['Requests']
        }),
        createTransferRequest: build.mutation({
            query: (body={} ) => ({
                url: `budget-transfer/create`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Requests']
        }),
        approveTransfer: build.mutation({
            query: (body) => ({
                url: `budget-transfer/approve`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Requests']
        }),
        rejectTransfer: build.mutation({
            query: (body) => ({
                url: `budget-transfer/reject`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Requests']
        }),
        getCategories: build.query({
            query: () => `requests/categories`,
        }),
    }),
})

export const {
    useGetTransferRequestsQuery,
    useGetAccrualRequestsQuery,
    useCreateTransferRequestMutation,
    useApproveTransferMutation,
    useRejectTransferMutation,
    useGetCategoriesQuery
} = accrualRequestApi