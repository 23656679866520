import {utcToZonedTime } from "date-fns-tz";

export const COINS_PRESENT_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/coin.png"
export const YEAR_1_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/1_year.png"
export const YEAR_3_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/3_years.png"
export const YEAR_5_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/5_years.png"
export const YEAR_7_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/7_years.png"
export const YEAR_10_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/10_years.png"
export const YEAR_15_LINK = "https://storage.googleapis.com/static-dev.ita.belka-games.com/15_years.png"

export function getSeniorityButtonImage(voucher) {
    switch (voucher.type) {
        case 'YEAR_1':
            return YEAR_1_LINK;
        case 'YEAR_3':
            return YEAR_3_LINK;
        case 'YEAR_5':
            return YEAR_5_LINK;
        case 'YEAR_7':
            return YEAR_7_LINK;
        case 'YEAR_10':
            return YEAR_10_LINK;
        case 'YEAR_15':
            return YEAR_15_LINK;
        default:
            return YEAR_1_LINK;
    }
}

export function getSeniorityResentTitle(voucher) {
    let title = "Подарочный пак на юбилей работы в компании: ";
    switch (voucher) {
        case 'YEAR_1':
            return title + "1 год";
        case 'YEAR_3':
            return title + "3 года";
        case 'YEAR_5':
            return title + "5 лет";
        case 'YEAR_7':
            return title + "7 лет";
        case 'YEAR_10':
            return title + "10 лет";
        case 'YEAR_15':
            return title + "15 лет";
        default:
            return "Подарочный пак на юбилей работы в компании";
    }
}

export function getTabMainState(tabs, locationPath, hasSeniority) {
    let tabsState = 0;
    tabs.map((tab, index)=>{
        if(tab.path === locationPath || locationPath.includes(tab.path)) {
            if (hasSeniority) {
                tabsState = index;
            } else {
                tabsState = (index - 1) >= 0 ? tabsState = index - 1 : 0;
            }

        }
    })
    return tabsState;
}

export function getTabState(tabs, locationPath) {
    let tabsState = 0;
    tabs.map((tab, index)=>{
        if(tab.path === locationPath || locationPath.includes(tab.path)) {
            tabsState = index;
        }
    })
    return tabsState;
}

export function mapOrderStatus(status) {
    switch (status) {
        case 'CREATED':
            return 'Создан';
        case 'WAITING':
            return 'Изготовление';
        case 'PROGRESS':
            return 'В работе';
        case 'DELIVERY':
            return 'Доставка';
        case 'READY':
            return 'Готово';
        case 'CANCELED':
            return 'Отменен';
        default:
            return 'Новый';
    }
}

export function isAdminMode(pathName) {
    return pathName.includes('admin');
}

export function getDateInServerTimeZone(date) {
    if (date !== null) {
        const timeZone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        return  utcToZonedTime(date, timeZone);
    } else {
        return new Date();
    }

}

export function parseFormDataTextToJsonString(text) {
    if (!text.includes("form-data")) {
        return JSON.stringify(text);
    } else {
        let formDataStr = text.split("form-data; name=");
        let objectArray = [];
        formDataStr.forEach(subString => {
            const data = subString.substring(subString.indexOf("\\"), subString.indexOf('--'))
                .replaceAll("\r\n\r\n", ":")
                .replaceAll("\r\n", "");
            objectArray.push(data)
        })
        return objectArray.join(' ');
    }
}

export function findCategoryLimit (selectedCategory, categories) {
    let cat = findCategoryByName(selectedCategory, categories);
    const currentMonthNumber = new Date().getMonth();
    if (cat !== null && cat.monthsBudget.length >= currentMonthNumber) {
        const monthBudget = cat.monthsBudget[currentMonthNumber];
        if (monthBudget === undefined || monthBudget === null) return 0
        return monthBudget.sum;
    } else {
        return 0;
    }
}

export function findCategoryByName(selectedCategory, categories)  {
    let cat = null;
    if (categories !== undefined && categories !== null) {
        categories.map(category => {
            if (category.name === selectedCategory) {
                cat = category;
            }
        });

    }
    return cat;
}
