import * as React from 'react';
import {
    Box,
    CircularProgress,
    TextField
} from "@mui/material";
import {format} from "date-fns";
import {useState} from "react";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import ExportGridToExcel from "../ExportGridToExcel";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {useGetBudgetHistoryQuery} from "../../../core/api/budget-api";
import {getDateInServerTimeZone} from "../../../core/utils/utils";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'budget', headerName: 'Категория', width: 200},
    {field: 'sum', headerName: 'Количество', width: 150},
    {field: 'changeType', headerName: 'Тип', width: 200},
    {field: 'updatedAt', headerName: 'Дата', width: 150},
    {field: 'month', headerName: 'Месяц', width: 150},
    {field: 'authorName', headerName: 'Автор', width: 200},
    {field: 'comment', headerName: 'Комментарий', width: 300},
    {field: 'receiverName', headerName: 'Получатели', width: 400},

];
export default function BudgetHistory() {
    const [state, setState] = useState({
        dateFrom: new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 30),
        dateTo: new Date(),
    });

    const {data: balances = [], isFetching} = useGetBudgetHistoryQuery({
        dateFrom: format(state.dateFrom, "dd.MM.yyyy HH:mm"),
        dateTo: format(state.dateTo, "dd.MM.yyyy HH:mm"),
    });


    return (
        isFetching ?
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box> :
            <Box sx={{mt: 2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Начало периода"
                        value={state.dateFrom}
                        onChange={(newValue) => {
                            setState({
                                ...state,
                                dateFrom: newValue
                            });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Конец периода"
                        value={state.dateTo}
                        onChange={(newValue) => {
                            setState({
                                ...state,
                                dateTo: newValue
                            });
                        }}
                        renderInput={(params) => <TextField
                            sx={{mr: 2}}
                            {...params} />}
                    />
                </LocalizationProvider>

                <ExportGridToExcel
                    filename={"budget_history"}
                    data={balances}/>
                <Box sx={{width: '100%', height: 600, mt: 2}}>
                    <DataGrid
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={balances}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Box>
            </Box>

    );
}