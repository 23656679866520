import * as React from 'react';
import PropTypes from "prop-types";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useEffect, useState} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const excludeDeprecatedSelected = (defaultSelectedItems, items)=>  {
    return defaultSelectedItems.filter(defaultSelectedItem => items.includes(defaultSelectedItem))
}

export default function MultipleSelect(props) {
    const [state, setState] = useState(
        {selectedItems: props.defaultSelectedItems ?
                excludeDeprecatedSelected(props.defaultSelectedItems, props.items) : []})

    useEffect(() => {
        props.setSelectedItems(state.selectedItems)
    }, [state.selectedItems])

    useEffect(() => {
        if (props.clearSelected) {
            console.log(props.clearSelected)

            setState({
                ...state,
                selectedItems: []
            })
            props.setIsNeedToClear(false)
        }
    }, [props.clearSelected])

    /*useEffect(() => {
        setState({
            ...state,
            selectedItems: props.defaultSelectedItems ? excludeDeprecatedSelected(props.defaultSelectedItems, props.items) : []
        })
        props.setSelectedItems(state.selectedItems)
    }, [props.defaultSelectedItems])*/


    const handleChangeAvailableLocation = (event) => {
        const value = event.target.value
        setState({...state, selectedItems: typeof value === 'string' ? value.split(',') : value})
        props.setSelectedItems(state.selectedItems)
    };
    return (
        <FormControl
            fullWidth
            sx={{mt: 3}}>
            <InputLabel
                color="warning"
                id={props.labelId}>
                {props.label}
            </InputLabel>
            <Select
                labelId="new-product-availability-label"
                id="new-product-availability-multiple-checkbox"
                multiple
                value={state.selectedItems}
                color="warning"
                onChange={handleChangeAvailableLocation}
                input={<OutlinedInput label="Локации"/>}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {props.items && props.items.map((item) => (
                    <MenuItem key={`${props.label}_${item}`}
                              value={item}>
                        <Checkbox checked={state.selectedItems.indexOf(item) > -1}/>
                        <ListItemText primary={item}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

MultipleSelect.propTypes = {
    label: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    defaultSelectedItems: PropTypes.array,
    setSelectedItems: PropTypes.func.isRequired,
    clearSelected:  PropTypes.bool.isRequired,
}