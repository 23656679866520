import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ImageUploadButton from "./ImageUploadButton";
import MultipleSelect from "./MultipleSelect";
import {useCreateProductMutation, useGetSizesQuery, useUpdateProductMutation} from "../../core/api/products-api";
import {LoadingButton} from "@mui/lab";
import {COUNTRIES} from "../../constants";
import "./ProductCreateDialog.css";
import ProductVariationAmountDialog from "./ProductVariationAmountDialog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const editorConfiguration = {
    toolbar: [
        'undo', 'redo',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|',
        'heading'
    ]
};

export default function CreateProductDialog(props) {
    const {onClose, open} = props;
    const [crateProduct, createResponse] = useCreateProductMutation()
    const [updateProduct, updateResponse] = useUpdateProductMutation()
    const {data: sizes = [], isFetching, isSuccess} = useGetSizesQuery();

    const getProductTitle = (product) => {
        if (product) {
            if (props.isCopyMode) {
                return props.product.title + " (копия)";
            } else {
                return props.product.title;
            }
        } else {
            return "";
        }
    }

    const [state, setState] = useState({
        title: getProductTitle(props.product),
        description: props.product ? props.product.description : "",
        availableLocations: props.product ? props.product.availability : [],
        availableSizes: props.product ? props.product.variations : [],
        uploadedProductImages: [],
        uploadedSizeTable: [],
        price: props.product ? props.product.price : 0,
        amount: props.product ? (props.product.amount !== null ? props.product.amount: 0 ) : 0,
        isSale:  props.product && props.product.isSale ? props.product.isSale : false,
        isSeniority: props.product && props.product.isSeniority ? props.product.isSeniority : false,
        isForSelect: props.product && props.product.isForSelect ? props.product.isForSelect : false,
        voucherType: props.product && props.product.voucherType ? props.product.voucherType : "",
        salePrice: props.product && props.product.salePrice ? props.product.salePrice : 0,
        isLimited: props.product ? props.product.isLimited : false,
        limitedText: props.product ? props.product.limitedText : "",
        dialogResponse: {isUpdate: false, responseData: {}},
        isResponseOpenDialog: false,
        isActionButtonProductClick: false,
        isResponseReady: false,
        isRequestCreateLoading: false,
        errors: {},
        isOpenSizeAmountDialog: false,
        variationsAmountMap: props.product && props.product.variationsAmount != null ? new Map(Object.entries(props.product.variationsAmount)) : new Map(),
        storageAmounts: props.product && props.product.storageAmounts != null ? props.product.storageAmounts : [],
        editorState: "",
        isCharity: false
    })

    useEffect(() => {
        const isRequestLoadingDialog = state.isActionButtonProductClick &&
            (updateResponse.status !== 'rejected' || updateResponse.status !== 'fulfilled' ||
                createResponse.status !== 'rejected' || createResponse.status !== 'fulfilled');

        setState({
            ...state,
            isRequestCreateLoading: isRequestLoadingDialog,
            isActionButtonProductClick: false
        })
        if (updateResponse.status === 'rejected' || updateResponse.status === 'fulfilled') {
            setState({
                ...state,
                isResponseReady: true,
                dialogResponse: {isUpdate: true, responseData: updateResponse},
                isResponseOpenDialog: true
            })
            handleClose(true, {
                isUpdate: true,
                responseData: updateResponse
            })
        } else if (createResponse.status === 'rejected' || createResponse.status === 'fulfilled') {
            setState({
                ...state,
                isResponseReady: true,
                dialogResponse: {isUpdate: false, responseData: createResponse},
                isResponseOpenDialog: true
            })
            handleClose(true, {
                isUpdate: false,
                responseData: createResponse
            })
        }

    }, [updateResponse, createResponse, sizes])

    const calculateInitStorageAmount = () => {
        if (state.storageAmounts.length !== 0) {
            let storage = [...state.storageAmounts];

            state.availableLocations.forEach(location => {
                let isNeedToAdd = true;
                state.storageAmounts.forEach(storageAmounts => {
                    if (storageAmounts.location === location) {
                        isNeedToAdd = false;
                    }
                });
                if (isNeedToAdd) {
                    let sizeAmountMap = new Map();
                    state.availableSizes.forEach(value => {
                        sizeAmountMap.set(value, 0);
                    });
                    storage.push({
                        location: location,
                        variationsAmountMap: sizeAmountMap,
                        amount: 0
                    })

                }
            })
            let storageToDelete = [];
            state.storageAmounts.forEach((storage, index) => {
                let isNeedToDelete = true;
                state.availableLocations.forEach(location => {
                    if (location === storage.location) {
                        isNeedToDelete = false;
                    }
                });
                if (isNeedToDelete) {
                    storageToDelete.push(index);
                }
            });
            if (storageToDelete.length !== 0) {
                storageToDelete.forEach(item =>{
                    storage.splice(item, 1);
                })
            }
            return storage;
        }
        let sizeAmount = [];
        if (state.availableSizes.length !== 0) {
            let sizeAmountMap = new Map();
            state.availableSizes.forEach(value => {
                sizeAmountMap.set(value, 0);
            });
            state.availableLocations.map(location => {
                sizeAmount.push({
                    location: location,
                    amount: null,
                    variationsAmountMap: sizeAmountMap
                })
            })
        } else {
            state.availableLocations.map(location => {
                sizeAmount.push({
                    location: location,
                    variationsAmountMap: new Map(),
                    amount: 0
                })
            })
        }
        return sizeAmount;

    };

    const handleChangeTitle = (event) => {
        setState({
            ...state,
            title: event.target.value
        })
    };
    const handleChangeDescription = (event) => {
        setState({
            ...state,
            description: event.target.value
        })
    };
    const handleChangePrice = (event) => {
        setState({
            ...state,
            price: event.target.value
        })
    };
    const handleChangeAmount = (event) => {
        setState({
            ...state,
            amount: event.target.value
        })
    };
    const handleChangeSalePrice = (event) => {
        setState({
            ...state,
            salePrice: event.target.value
        })
    };

    const handleChangeLimitedText = (event) => {
        setState({
            ...state,
            limitedText: event.target.value
        })
    };
    const handleClose = (isResponseReady, responseData) => {
        setState({
            ...state,
            isResponseReady: false,
            isRequestCreateLoading: false,
            isActionButtonProductClick: false,
            errors: {}
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: responseData
        });
    };

    const handleEditAmountBySize = (event) => {
        setState({
            ...state,
            isOpenSizeAmountDialog : true
        });
    };

    const onCloseProductSizeDialog = (amountSizeMap) => {
        setState({
            ...state,
            storageAmounts: amountSizeMap,
            variationsAmountMap: new Map(),
            isOpenSizeAmountDialog : false
        });
    }

    const getFormData = (object) => {
        const formData = new FormData()
        Object.keys(object).forEach(key => {
                if (key === "images" && object[key] !== null) {
                    for (let i = 0; i < object[key].length; i++) {
                        formData.append(`images[${i}]`, object[key][i])
                    }
                } else if (key === "sizeTableImage" && object[key] !== null) {
                    for (let i = 0; i < object[key].length; i++) {
                        formData.append(`sizeTable[${i}]`, object[key][i])
                    }
                } else if (key === "variationsAmount" && object[key] !== null) {
                    object[key].forEach((value, key) => {
                        formData.append(`variationsAmount[${key}]`, value)
                    });

                }else if (key === "storageAmounts" && object[key] !== null) {
                    object[key].forEach((obj, index) => {
                        formData.append(`storageAmounts[${index}].location`, obj.location);
                        formData.append(`storageAmounts[${index}].amount`, obj.amount == null ? 0 : obj.amount);

                        if (obj.variationsAmountMap !== null) {
                            obj.variationsAmountMap.forEach((value, key) => {
                                formData.append(
                                    `storageAmounts[${index}].variationsAmountMap[${key}]`,
                                    value
                                );
                            });
                        }
                    });

                }
                else {
                    formData.append(key, object[key])
                }
            }
        )
        return formData
    }
    const handleSaveButton = () => {
        if (validateRequiredFields()) {
            crateProduct(getFormData({
                    copiedId: props.product ? props.product.id : null,
                    isCopyMode: props.isCopyMode,
                    title: state.title,
                    description: state.description,
                    price: state.price,
                    isSeniority: state.isSeniority,
                    isForSelect: state.isForSelect,
                    voucherType: state.voucherType,
                    images: state.uploadedProductImages.images !== undefined ? state.uploadedProductImages.images : [],
                    sizeTableImage: state.uploadedSizeTable.images !== undefined ? state.uploadedSizeTable.images : [],
                    availability: state.availableLocations,
                    variations: state.availableSizes,
                    sortingOrder: 0,
                    amount: state.amount,
                    storageAmounts: state.storageAmounts,
                    variationsAmount: state.variationsAmountMap,
                    isLimited: state.isLimited,
                    limitedText: state.limitedText,
                    isHidden:true,
                    isCharity: state.isCharity

                })
            )
            setState({
                ...state,
                isActionButtonProductClick: true
            })
        }
    }
    const handleUpdateButton = () => {
        if (validateRequiredFields()) {
            updateProduct(getFormData({
                    objectId: props.product.id,
                    title: state.title,
                    description: state.description,
                    price: state.price,
                    images: state.uploadedProductImages.images !== undefined ? state.uploadedProductImages.images : [],
                    sizeTableImage: state.uploadedSizeTable.images !== undefined ? state.uploadedSizeTable.images : [],
                    availability: state.availableLocations,
                    variations: state.availableSizes,
                    isSale: state.isSale,
                    isSeniority: state.isSeniority,
                    isForSelect: state.isForSelect,
                    voucherType: state.voucherType,
                    salePrice: state.salePrice,
                    amount: state.amount,
                    storageAmounts: state.storageAmounts,
                    variationsAmount: state.variationsAmountMap,
                    isLimited: state.isLimited,
                    limitedText: state.limitedText,
                    isCharity: state.isCharity

                })
            )
            setState({
                ...state,
                isActionButtonProductClick: true
            })
        }
    }
    const validateRequiredFields = () => {
        let errors = {};
        if (state.title.trim() === "") {
            errors = {...errors, title: true}
        }
        if (state.amount === undefined || state.amount === null) {
            errors = {...errors, amount: true};
        }
        if (state.price < 0) {
            errors = {...errors, price: true};
        }
        if (state.availableSizes !== null  && state.uploadedSizeTable.length > 1) {
            errors = {...errors, sizeTable: true}
        }
        if (state.isSale === true) {
            if (state.salePrice <= 0) {
                errors = {...errors, salePrice: true};
            }
        }
        if (state.isLimited && state.limitedText.trim() === "") {
            errors = {
                ...errors,
                limitedText: true
            }
        }
        setState({
            ...state,
            errors: errors
        })
        return Object.keys(errors).length === 0;
    }

    const handleSetAvailableLocation = (values) => {
        setState({
            ...state,
            availableLocations: values
        })
    }

    const handleSetAvailableSize = (values) => {
        setState({
            ...state,
            availableSizes: values
        })
    }

    const handleSetUploadedProductImages = (values) => {
        setState({
            ...state,
            uploadedProductImages: values
        })
    }
    const handleSetUploadedSizeTableImages = (values) => {
        setState({
            ...state,
            uploadedSizeTable: values
        })
    }
    const handleCheckboxChange = (event) => {
        setState({
            ...state,
            isSale: event.target.checked
        })
    }

    const handleCharityChange = (event) => {
        setState({
            ...state,
            isCharity: event.target.checked
        })
    }
    const handleSeniorityChange = (event) => {
        setState({
            ...state,
            isSeniority: event.target.checked
        })
    }
    const handleForSelectChange = (event) => {
        setState({
            ...state,
            isForSelect: event.target.checked
        })
    }
    const handleLimitedCheckboxChange = (event) => {
        setState({
            ...state,
            isLimited: event.target.checked
        })
    }

    function handleVoucherTypeChange(event) {
        setState({
            ...state,
            voucherType: event.target.value
        })
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                {props.isEditMode ? props.isCopyMode ? "Копирование продукта": "Редактирование продукта" : "Создание продукта"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{mt: 3}}
                    required
                    value={state.title}
                    id="new-product-title"
                    label="Название продукта"
                    variant="outlined"
                    color="warning"
                    onChange={handleChangeTitle}
                    fullWidth
                    name="title"
                    error={state.errors.title}
                    helperText={state.errors.title
                        ? "Поле не должно быть пустым."
                        : ""}
                />
                <div>
                    <div className={"description-editor-label"}>Описание продукта</div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={state.description}
                        config={{
                            toolbar: [
                                'undo',
                                'redo',
                                '|',
                                'bold',
                                'italic',
                                'link',
                                'bulletedList',
                                'numberedList',
                                'blockQuote',
                                '|',
                                'heading'
                            ]
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setState({
                                ...state,
                                description: data
                            })
                        }}
                    />
                </div>

                <FormControlLabel
                    control={<Checkbox   checked={state.isCharity}
                                         onChange={handleCharityChange}
                                         inputProps={{ 'aria-label': 'controlled' }}/>}
                    label="Продукт благотворительности"
                />
                {
                    !state.isCharity && <FormControlLabel
                        control={<Checkbox checked={state.isSeniority}
                                           onChange={handleSeniorityChange}
                                           inputProps={{'aria-label': 'controlled'}}/>}
                        label="Seniority program"
                    />
                }

                {
                    (!state.isSeniority) &&
                    <Box>
                        <TextField
                            required
                            sx={{mt: 3}}
                            value={state.price}
                            id="new-product-price"
                            label="Цена"
                            variant="outlined"
                            color="warning"
                            onChange={handleChangePrice}
                            type={"number"}
                            fullWidth
                            name="price"
                            error={state.errors.price}
                            helperText={state.errors.price
                                ? "Некорректное значение поля цена"
                                : ""}
                        />

                        <MultipleSelect label={"Доступные локации"}
                                        labelId={"availableLocations"}
                                        items={COUNTRIES}
                                        defaultSelectedItems={state.availableLocations}
                                        setSelectedItems={handleSetAvailableLocation}
                                        clearSelected={false}
                                        setIsNeedToClear={null}
                        />
                    </Box>

                }
                {
                    state.isSeniority &&
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel color="warning">
                                Годовщина
                            </InputLabel>
                            <Select
                                color="warning"
                                labelId="location-select-label"
                                id="location-select"
                                value={state.voucherType}
                                label="location"
                                onChange={handleVoucherTypeChange}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key={'YEAR_1'} value={'YEAR_1'}> 1 год</MenuItem>
                                <MenuItem key={'YEAR_3'} value={'YEAR_3'}> 3 года</MenuItem>
                                <MenuItem key={'YEAR_5'} value={'YEAR_5'}> 5 лет</MenuItem>
                                <MenuItem key={'YEAR_7'} value={'YEAR_7'}> 7 лет</MenuItem>
                                <MenuItem key={'YEAR_10'} value={'YEAR_10'}> 10 лет</MenuItem>
                                <MenuItem key={'YEAR_15'} value={'YEAR_15'}> 15 лет</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox   checked={state.isForSelect}
                                                 onChange={handleForSelectChange}
                                                 inputProps={{ 'aria-label': 'controlled' }}/>}
                            label="Продукт нужно будет выбрать"
                        />

                    </Box>
                }
                {!state.isCharity &&
                    <Box>
                        <MultipleSelect label={"Доступные размеры"}
                                        labelId={"availableSizes"}
                                        items={isSuccess ? sizes : []}
                                        defaultSelectedItems={state.availableSizes}
                                        setSelectedItems={handleSetAvailableSize}
                                        clearSelected={false}
                                        setIsNeedToClear={null}
                        />

                        <Button
                            variant="outlined"
                            color="warning"
                            sx={{mt: 3, ml: 16}}
                            onClick={handleEditAmountBySize}>
                            Указать количество товара на складе
                        </Button>
                    </Box>
                }
                <Box>
                    <ImageUploadButton
                        label={"product-image-upload-button"}
                        labelId={"product-image-upload-button"}
                        buttonTitle={"Загрузить фото товара"}
                        setUploadedImagesArray={handleSetUploadedProductImages}/>
                </Box>
               <Box>
                   { state.availableSizes !== null && state.availableSizes.length !== 0 &&
                       <Box>
                           <ImageUploadButton
                               label={"size-table-image-upload"}
                               labelId={"size-table-image-upload"}
                               buttonTitle={"Загрузить таблицу размеров (если необходимо)"}
                               setUploadedImagesArray={handleSetUploadedSizeTableImages}/>
                       {state.errors.sizeTable &&
                           <p className="error-helper-text-center">
                           *Допустима только одна таблица размеров для товаров
                           </p>}
                       </Box>
                   }
                </Box>
                {
                    !state.isCharity &&
                    <Box>
                        <FormControlLabel
                            control={<Checkbox   checked={state.isLimited}
                                                 onChange={handleLimitedCheckboxChange}
                                                 inputProps={{ 'aria-label': 'controlled' }}/>}
                            label="Продукт лимитированной серии"
                        />
                    </Box>
                }

                {state.isLimited &&
                    <TextField
                        required
                        sx={{mt: 3}}
                        value={state.limitedText}
                        id="new-product-sale-price"
                        label="Текст для ярлычка"
                        variant="outlined"
                        color="warning"
                        onChange={handleChangeLimitedText}
                        fullWidth
                        name="limitedText"
                        error={state.errors.limitedText}
                        helperText={state.errors.limitedText
                            ? "Поле не может быть пустым"
                            : ""}
                    /> }
                {
                    !state.isCharity &&
                    <FormControlLabel
                        control={<Checkbox   checked={state.isSale}
                                             onChange={handleCheckboxChange}
                                             inputProps={{ 'aria-label': 'controlled' }}/>}
                        label="На продукт действует скидка"
                    />
                }

                {state.isSale &&
                <TextField
                    required
                    sx={{mt: 3}}
                    value={state.salePrice}
                    id="new-product-sale-price"
                    label="Цена со скидкой"
                    variant="outlined"
                    color="warning"
                    onChange={handleChangeSalePrice}
                    type={"number"}
                    fullWidth
                    name="price"
                    error={state.errors.salePrice}
                    helperText={state.errors.salePrice
                        ? "Некорректное значение поля цена"
                        : ""}
                /> }
                <ProductVariationAmountDialog open={state.isOpenSizeAmountDialog}
                                              onClose={onCloseProductSizeDialog}
                                              locations={state.availableLocations}
                                              variation={state.availableSizes}
                                              locationSizes={calculateInitStorageAmount()}/>

            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена
                </Button>
                {
                    (props.isEditMode && !props.isCopyMode) ?
                        <LoadingButton
                            size="small"
                            onClick={handleUpdateButton}
                            loading={state.isRequestCreateLoading}
                            variant="outlined"
                            color="warning"
                        >
                            Обновить
                        </LoadingButton> :
                        <LoadingButton
                            size="small"
                            onClick={handleSaveButton}
                            loading={state.isRequestCreateLoading}
                            variant="outlined"
                            color="warning"
                        >
                            Сохранить
                        </LoadingButton>
                }

            </DialogActions>
        </Dialog>
    );
}

CreateProductDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isCopyMode: PropTypes.bool.isRequired,
    refetchProduct: PropTypes.func,
    product: PropTypes.object
};
